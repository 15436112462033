
import React from "react";
import "./welcome-year.css";

const WelcomeYear = () => {
  return (
    <div className="welcome-year">
      <h1 className="welcome-year-title">2024</h1>
    </div>
  );
};
export default WelcomeYear;
